<div class="instructions-edit" cdkDropList (cdkDropListDropped)="drop($event)">
  @for (instruction of instructions; track instruction) {
    <div class="instruction" cdkDrag>
      <div class="what field">
        <div class="col-5">
          <hpm-icon-select [(icon)]="instruction.icon" [editMode]="true"></hpm-icon-select>
        </div>
        <div class="col-7" [style.background-color]="instruction.colorCode ? '#' + instruction.colorCode : ''"
             (focusout)="validateText({text: instruction.what, index: $index, field: 'what'})">
          <hpm-instruction-field-edit [(text)]="instruction.what"
                                      [(backgroundColor)]="instruction.colorCode"
          ></hpm-instruction-field-edit>
        </div>
      </div>
      <div class="field" (focusout)="validateText({text: instruction.when, index: $index, field: 'when'})">
        <hpm-instruction-field-edit [(text)]="instruction.when"
                                    [(backgroundColor)]="instruction.colorCode"
        ></hpm-instruction-field-edit>
      </div>
      <div class="field" (focusout)="validateText({text: instruction.how, index: $index, field: 'how'})">
        <hpm-instruction-field-edit [(text)]="instruction.how"
                                    [(backgroundColor)]="instruction.colorCode"
        ></hpm-instruction-field-edit>
      </div>
      <div class="field" [style.background-color]="instruction.colorCode ? '#' + instruction.colorCode : ''"
           (focusout)="validateText({text: instruction.where, index: $index, field: 'where'})">
        <hpm-instruction-field-edit [(text)]="instruction.where"
                                    [(backgroundColor)]="instruction.colorCode"
        ></hpm-instruction-field-edit>
      </div>
      <div class="field" (focusout)="validateText({text: instruction.dosage, index: $index, field: 'dosage'})">
        <hpm-instruction-field-edit [(text)]="instruction.dosage"
                                    [(backgroundColor)]="instruction.colorCode"
        ></hpm-instruction-field-edit>
      </div>
      <div class="field" (focusout)="validateText({text: instruction.who, index: $index, field: 'who'})">
        <hpm-instruction-field-edit [(text)]="instruction.who"
                                    [(backgroundColor)]="instruction.colorCode"
        ></hpm-instruction-field-edit>
      </div>
      <div class="instruction-edit-buttons">
        <button mat-icon-button cdkDragHandle>
          <mat-icon>drag_indicator</mat-icon>
        </button>
        <button mat-icon-button (click)="addInstruction($index)">
          <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteInstruction($index)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div *cdkDragPreview>
        <!--      we do not want a preview as it is kinda messy-->
      </div>
    </div>
  }
</div>
