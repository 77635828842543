import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Editor, Toolbar } from 'ngx-editor';
import { customSchema } from './editor-config';
import {
  fontSizes,
  templateColors,
} from '../../../../../../shared/model/templateColors';

@Component({
  selector: 'hpm-instruction-field-edit',
  templateUrl: './instruction-field-edit.component.html',
  styleUrl: './instruction-field-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InstructionFieldEditComponent implements OnInit, OnDestroy {
  editor!: Editor;
  toolbar: Toolbar = [['bold', 'italic', 'underline'], []];
  colorPickerPalette = templateColors;
  fontSizes = fontSizes;

  @Input() text!: string;
  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() backgroundColor?: string;
  @Output() backgroundColorChange: EventEmitter<string> =
    new EventEmitter<string>();
  defaultFontSize = 12;

  ngOnInit(): void {
    this.editor = new Editor({ schema: customSchema });
  }

  ngOnDestroy(): void {
    if (this.editor) {
      this.editor.destroy();
    }
  }

  selectedColor(colorHexCode: string): void {
    if (colorHexCode !== null) {
      this.backgroundColor = colorHexCode.substring(1);
      this.backgroundColorChange.emit(this.backgroundColor);
    }
  }

  setFontSize(size: number): void {
    const { state, dispatch } = this.editor.view;
    const { tr } = state;
    tr.addMark(
      state.selection.from,
      state.selection.to,
      state.schema.marks['fontSize'].create({ fontSize: size }),
    );
    dispatch(tr);
  }

  increaseFontSize(): void {
    this.adjustFontSize(2);
  }

  decreaseFontSize(): void {
    this.adjustFontSize(-2);
  }

  getFontSize(): number {
    const { state } = this.editor.view;
    const { from, to } = state.selection;
    let nodeFontSize = this.defaultFontSize;

    state.doc.nodesBetween(from, to, (node) => {
      const fontSizeMark = node.marks.find(
        (mark) => mark.type === state.schema.marks['fontSize'],
      );
      if (fontSizeMark) {
        nodeFontSize = parseInt(fontSizeMark.attrs['fontSize'], 10);
      }
    });
    return nodeFontSize;
  }

  private adjustFontSize(change: number): void {
    const { state, dispatch } = this.editor.view;
    const { from, to } = state.selection;
    let adjustedFontSizeWithExistingMark = false;

    state.doc.nodesBetween(from, to, (node, pos) => {
      if (from === pos && to === pos + node.nodeSize) {
        const fontSizeMark = node.marks.find(
          (mark) => mark.type === state.schema.marks['fontSize'],
        );
        if (fontSizeMark) {
          const currentSize = parseInt(fontSizeMark.attrs['fontSize'], 10);
          const newSize = currentSize + change;
          if (this.fontSizes.includes(newSize)) {
            const tr = state.tr;
            tr.removeMark(
              pos,
              pos + node.nodeSize,
              state.schema.marks['fontSize'],
            );
            tr.addMark(
              pos,
              pos + node.nodeSize,
              state.schema.marks['fontSize'].create({ fontSize: `${newSize}` }),
            );
            dispatch(tr);
            adjustedFontSizeWithExistingMark = true;
          }
        }
      }
    });
    if (!adjustedFontSizeWithExistingMark) {
      const newSize = this.getFontSize() + change;
      if (this.fontSizes.includes(newSize)) {
        this.setFontSize(newSize);
      }
    }
  }

  getTextSelection(): string {
    const { state } = this.editor.view;
    const { from, to } = state.selection;
    return state.doc.textBetween(from, to, '');
  }

  insertText(placeholder: string): void {
    const { state, dispatch } = this.editor.view;
    const { from, to } = state.selection;
    dispatch(state.tr.insertText(placeholder, from, to));
  }
}
