import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { Instruction } from '../../../model/instruction.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'hpm-instruction-table',
  templateUrl: './instruction-table.component.html',
  styleUrl: './instruction-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // modify innerHtml
  encapsulation: ViewEncapsulation.None,
})
export class InstructionTableComponent {
  @Input() instructions!: Instruction[];

  constructor(private sanitizer: DomSanitizer) {}

  trustHtml(text: string): SafeHtml {
    if (text) {
      // we need to bypass security. otherwise font-size and color span tags are removed
      return this.sanitizer.bypassSecurityTrustHtml(text);
    } else {
      return '';
    }
  }
}
