import { Injectable } from '@angular/core';
import { PlaceholderObject } from './placeholder-edit.component';

@Injectable({
  providedIn: 'root',
})
export class PlaceholderService {
  private readonly placeholderRegex =
    /{?\s*'([^']*)'\.'([^']*)'\.'([^']*)'\s*}?/;

  parsePlaceholder(placeholderString: string): PlaceholderObject {
    if (!placeholderString || placeholderString === '') {
      return {};
    } else {
      const match = this.placeholderRegex.exec(placeholderString);
      if (match !== null) {
        let val = this.getElement(match[3]);
        if (val !== undefined) {
          val = val.replaceAll(`'`, '');
        }
        return {
          area: this.getElement(match[1]),
          element: this.getElement(match[2]),
          field: val,
        };
      } else {
        return {};
      }
    }
  }

  convertToPlaceholderString(
    placeholder: PlaceholderObject | undefined,
  ): string {
    if (!placeholder?.area) {
      return '';
    }
    const area = placeholder.area ? placeholder.area : '';
    const element = placeholder.element ? placeholder.element : '';
    const field = placeholder.field ? placeholder.field : '';
    return `{'${area}'.'${element}'.'${field}'}`;
  }

  private getElement(element: string): string | undefined {
    if (!element || element.trim() === '') {
      return undefined;
    } else {
      return element.trim();
    }
  }
}
